export const ACTIVE = "ACTIVE";
export const DEACTIVATED = "DEACTIVATED";
export const ENUM_ON = "ON";
export const ENUM_OFF = "OFF";

// Environment Device Types
export const DOOR_CONTACT = "DOOR_CONTACT";
export const WATER_LEAK = "WATER_LEAK";
export const SMOKE_DETECTOR = "SMOKE_DETECTOR";
export const TEMP_HUMIDITY = "TEMP_HUMIDITY";

// Device Dialog
export const ENIVIRONMENT_DEVICES = [
  WATER_LEAK,
  SMOKE_DETECTOR,
  TEMP_HUMIDITY,
  DOOR_CONTACT,
];
export const MAXCHAR_NAME = 20;

export const SelectDisplayProps = {
  paddingTop: "13px",
  paddingBottom: "12px",
  paddingLeft: "16px",
  height: "19px",
};

export const inputLabelSx = {
  fontFamily: "NotoSans-Regular",
  fontSize: "14px",
  letterSpacing: "0.01px",
  lineHeight: "24px",
};

export const menuPaperSx = { width: "376px" };

export const getEnvironmentOptions = () => {
  return ENIVIRONMENT_DEVICES.map((type, index) => {
    return {
      priority: index,
      label: type,
      value: type,
    };
  });
};

export const inputLabelProps = {
  sx: {
    fontSize: "14px",
    letterSpacing: "0.01px",
    top: "3px",
  },
};

export const inputProps = {
  sx: {
    height: "44px",
  },
};
