import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  BasicButton,
  ArrowRightIcon,
  CheckBlackIcon,
  DarkModeContext,
} from "@datwyler/shared-components";

const SiteMenu = (props) => {
  const {
    selectedDevice,
    devices,
    paddingTop,
    siteMenuWidth,
    setSelectedDevice,
  } = props;
  const { colors }: any = useContext(DarkModeContext);

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const handleOnSelectDevice = (device) => {
    setSelectedDevice(device);
  };

  const handleBackToDevice = () => {
    navigate("/device-monitoring", { state: location.state });
  };

  return (
    <Box
      sx={{
        width: siteMenuWidth,
        background: colors.siteMenuBg,
        paddingTop: paddingTop,
        paddingRight: "8px",
        paddingLeft: "16px",
        minHeight: `calc(100% - ${paddingTop})`,
      }}
    >
      <BasicButton
        variant={"text"}
        sx={{ padding: 0, justifyContent: "flex-start" }}
        onClick={handleBackToDevice}
      >
        <Box
          sx={{
            transform: "rotate(180deg)",
            display: "flex",
            marginRight: "10px",
          }}
        >
          <ArrowRightIcon fill={colors.iconColor} />
        </Box>
        <Typography
          sx={{
            color: colors.siteMenuBackFont,
            fontFamily: "NotoSans-Medium",
            fontSize: "14px",
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: "20px",
          }}
        >
          {intl.formatMessage({ id: "back_to_device" })}
        </Typography>
      </BasicButton>
      <Box sx={{ marginTop: "32px" }}>
        <List>
          {devices?.map((device) => {
            return (
              <ListItem key={device.id} disablePadding>
                <ListItemButton
                  sx={{
                    padding: 0,
                    ":hover": {
                      backgroundColor: colors.siteMenuHover,
                    },
                  }}
                  onClick={() => handleOnSelectDevice(device)}
                >
                  <Box
                    sx={{
                      backgroundColor:
                        device.id === selectedDevice.id
                          ? colors.siteMenuSelected
                          : "unset",
                      height: "20px",
                      width: "241px",
                      borderRadius: "8px",
                      display: "flex",
                      py: "14px",
                      px: "16px",
                      alignItems: "center",
                    }}
                  >
                    <ListItemText
                      primary={device.name}
                      primaryTypographyProps={{
                        fontSize: "14px",
                        fontFamily:
                          device.id === selectedDevice.id
                            ? "NotoSans-Medium"
                            : "NotoSans-Regular",
                        color:
                          device.id === selectedDevice.id
                            ? colors.siteMenuSelectedFont
                            : colors.siteMenuFont,
                        fontWeight:
                          device.id === selectedDevice.id ? 500 : "unset",
                        letterSpacing: 0,
                        lineHeight: "20px",
                      }}
                    />
                    {device.id === selectedDevice.id && (
                      <ListItemIcon sx={{ minWidth: "0px" }}>
                        <CheckBlackIcon />
                      </ListItemIcon>
                    )}
                  </Box>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default SiteMenu;
