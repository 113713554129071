import { useState, useEffect, useContext } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ResponsiveLine } from "@nivo/line";
import { getSignalData } from "../../../utils";
import { useIntl } from "react-intl";
import {
  getRequiredDateFormat,
  BasicCard,
  DarkModeContext,
} from "@datwyler/shared-components";

const SignalAreaChart = (props: any) => {
  const { data, device } = props;
  const [signalData, setSignalData] = useState(getSignalData(data, device));
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    setSignalData(getSignalData(data, device));
  }, [data, device]);

  const theme = {
    axis: {
      ticks: {
        line: {
          stroke: colors.chartAxisTick,
        },
        text: {
          fill: colors.chartAxisFont,
        },
      },
      legend: {
        text: {
          fill: colors.chartAxisFont,
        },
      },
    },
    legends: {
      text: {
        fill: colors.chartAxisFont,
      },
    },
  };

  const getTickValues = () => {
    const values = [];
    if (signalData[0]?.data) {
      const numTicks = 10;
      const max = signalData[0].data.length;
      const intervals = Math.floor(max / numTicks);

      if (intervals < 1) {
        signalData[0].data.forEach((d) => {
          values.push(d?.x);
        });
      } else {
        for (let i = 0; i < max; i = i + intervals) {
          values.push(signalData[0].data[i].x);
        }
      }
    }

    return values;
  };

  return (
    <BasicCard
      sx={{ height: 500, width: "100%", marginTop: "24px", padding: 0 }}
    >
      <CardContent sx={{ height: 400 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: colors.deviceCardHeaderFont }}
        >
          {intl.formatMessage({ id: "signal_strength_chart_title" })}
        </Typography>
        <ResponsiveLine
          data={signalData}
          enablePoints={false}
          enableGridX={false}
          colors={{ scheme: "category10" }}
          areaBaselineValue={-130}
          margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: -130,
            max: 0,
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="cardinal"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: intl.formatMessage({ id: "time" }),
            legendOffset: 36,
            legendPosition: "middle",
            tickValues: getTickValues(),
            format: (values) => `${getRequiredDateFormat(values, "HH:mm")}`, // using momentjs to format
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: intl.formatMessage({ id: "signal_strength" }) + " (dbM)",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          enableArea={true}
          useMesh={true}
          theme={theme}
        />
      </CardContent>
    </BasicCard>
  );
};

export default SignalAreaChart;
