import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DeviceCard from "./DeviceCard";
import { useNavigate, useLocation } from "react-router-dom";
import DeviceListTable from "./DeviceListTable";
import { getDevicesBySiteId } from "@datwyler/shared-components";

const DeviceList = (props) => {
  const {
    devices = [],
    layout,
    handleSetSelectedDevices,
    selectedDevices,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = (device) => {
    const devicesInSameSite = getDevicesBySiteId(devices, device?.site?.id);
    navigate("/device-monitoring/deviceDetails/", {
      state: {
        ...location.state,
        device: device,
        devices: devicesInSameSite,
      },
    });
  };

  return (
    <Box sx={{ marginTop: "24px", paddingBottom: "24px" }}>
      {layout === "grid" && (
        <Grid container spacing={3}>
          {devices.map((device, index) => {
            return (
              <Grid key={device.id} item xs={3}>
                <DeviceCard
                  key={device.id}
                  device={device}
                  handleOnClick={() => handleOnClick(device)}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {layout === "list" && (
        <DeviceListTable
          devices={devices}
          handleOnClick={handleOnClick}
          handleSetSelectedDevices={handleSetSelectedDevices}
          selectedDevices={selectedDevices}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={totalRows}
        />
      )}
    </Box>
  );
};

export default DeviceList;
