import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";
import { copyToClipboard, getTelemetryData } from "../../../../utils";
import LocationContext from "../../../../context/LocationContext";
import { useIntl } from "react-intl";
import { ENUM_ON } from "../../../../utils/constants";
import {
  BasicDataTable,
  BasicPagination,
  DarkModeContext,
  getLocationFromSiteId,
  useSnackbar,
} from "@datwyler/shared-components";

const headers = [
  "device_name",
  "type",
  "device_id",
  "location",
  "site",
  "status",
  "actions",
];

const columnConfigs = {
  checkbox: {
    colspan: 1,
    isCentralized: false,
  },
  device_name: {
    colspan: 2,
    isCentralized: false,
  },
  type: {
    colspan: 1.5,
    isCentralized: false,
  },
  device_id: {
    colspan: 2.5,
    isCentralized: false,
  },
  location: {
    colspan: 1.5,
    isCentralized: false,
  },
  site: {
    colspan: 1.5,
    isCentralized: false,
  },
  status: {
    colspan: 1,
    isCentralized: true,
  },
  actions: {
    colspan: 1,
    isCentralized: true,
  },
};

const DeviceListTable = (props) => {
  const {
    devices,
    handleOnClick,
    handleSetSelectedDevices,
    selectedDevices,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
  } = props;
  const { locations }: any = useContext(LocationContext);
  const [tableData, setTableData] = useState([]);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { colors }: any = useContext(DarkModeContext);

  const fontRegular = {
    color: colors.dataTableFont,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.02px",
    lineHeight: "20px",
    marginTop: "4px",
  };

  useEffect(() => {
    if (devices && locations) {
      setTableData(getData());
    }
  }, [devices, locations]);

  useEffect(() => {
    setTableData(getData());
  }, [page, rowsPerPage]);

  const getMoreButtonMenu = (device) => {
    return [
      {
        label: intl.formatMessage({ id: "copy_device_id" }),
        value: null,
        onClick: () => copyToClipboard(device, enqueueSnackbar, intl),
      },
    ];
  };

  const isDeviceSelected = (device) => {
    if (!selectedDevices) return false;
    return !!selectedDevices.find((s) => s.id === device.id);
  };

  const getData = () => {
    const data = [];
    const temp = JSON.parse(JSON.stringify(devices));

    temp.forEach((device) => {
      data.push({
        isChecked: isDeviceSelected(device) || false,
        device_name: renderDevice(device),
        device_id: renderDeviceId(device),
        type: renderType(device),
        status: renderStatus(device),
        location: renderLocation(device),
        site: renderSite(device),
        additionalInfo: device,
        moreButtonMenuItems: getMoreButtonMenu(device),
      });
    });

    return data;
  };

  const renderDevice = (device) => {
    return (
      <Box>
        <Typography sx={fontRegular}>{device.name}</Typography>
      </Box>
    );
  };

  const renderDeviceId = (device) => {
    return (
      <Box>
        <Typography sx={fontRegular} id={device.id}>
          {device.id}
        </Typography>
      </Box>
    );
  };

  const renderType = (device) => {
    return (
      <Box>
        <Typography sx={fontRegular}>
          {intl.formatMessage({ id: device.type })}
        </Typography>
      </Box>
    );
  };

  const renderStatus = (device) => {
    const status = getTelemetryData(device?.telemetry, "status");

    return (
      <Box>
        <CircleIcon
          sx={{
            color: status === ENUM_ON ? colors.green500 : colors.blueGray300,
            width: "10px",
            height: "10px",
          }}
        />
      </Box>
    );
  };

  const renderLocation = (device) => {
    const location = getLocationFromSiteId(locations, device?.site?.id);
    return (
      <Box>
        <Typography sx={fontRegular}>{location?.name}</Typography>
      </Box>
    );
  };

  const renderSite = (device) => {
    return (
      <Box>
        <Typography sx={fontRegular}>{device?.site?.name}</Typography>
      </Box>
    );
  };

  const handleClickRow = (additionalInfo) => {
    handleOnClick(additionalInfo);
  };

  const handleOnCheck = (additionalInfo) => {
    const temp: any = [...tableData];
    temp.map((row) => {
      if (row.additionalInfo.id === additionalInfo.id) {
        row.isChecked = !row.isChecked;
        let tempSelected = JSON.parse(JSON.stringify(selectedDevices));

        if (tempSelected.length < 1) {
          tempSelected.push(row.additionalInfo);
        } else {
          const index = tempSelected.findIndex(
            (s) => s.id === additionalInfo.id
          );
          if (index > -1 && !row.isChecked) {
            tempSelected.splice(index, 1);
          } else if (row.isChecked) {
            tempSelected.push(row.additionalInfo);
          }
        }

        handleSetSelectedDevices(tempSelected);
      }
    });
    setTableData(temp);
  };

  const handleCheckAll = (e) => {
    const temp = [...tableData];
    const tempSelected = [];
    temp.map((row: any) => {
      row.isChecked = e.target.checked;
      if (e.target.checked) {
        tempSelected.push(row.additionalInfo);
      }
    });

    handleSetSelectedDevices(tempSelected);
    setTableData(temp);
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      <BasicDataTable
        headers={headers}
        data={tableData}
        columnConfigs={columnConfigs}
        handleCheckAll={handleCheckAll}
        handleOnCheck={handleOnCheck}
        handleClickRow={handleClickRow}
      />
      <BasicPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default DeviceListTable;
