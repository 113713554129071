import React, { useState } from "react";
import App from "./App";
import {
  SnackBarProvider,
  useLocale,
  TranslationProvider,
  GraphQLApiProvider,
  ApiProvider,
  DarkModeProvider,
} from "@datwyler/shared-components";
import { BrowserRouter } from "react-router-dom";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);

  return (
    <React.StrictMode>
      <GraphQLApiProvider uri={props.GRAPHQL_URL}>
        <ApiProvider uri={props.REST_URL}>
          <BrowserRouter>
            <TranslationProvider locale={locale}>
              <DarkModeProvider>
                <SnackBarProvider>
                  <App />
                </SnackBarProvider>
              </DarkModeProvider>
            </TranslationProvider>
          </BrowserRouter>
        </ApiProvider>
      </GraphQLApiProvider>
    </React.StrictMode>
  );
}
