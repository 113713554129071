import { useEffect, useState } from "react";
import Router from "./Router";
import LocationContext from "./context/LocationContext";
import { useLocation as useWindowLocation } from "react-router-dom";
import {
  useLoadingGif,
  useLocation,
  TenantIdProvider,
} from "@datwyler/shared-components";

const App = () => {
  // locations refer to the MDC site locations
  // location here refers to react-router location
  const location = useWindowLocation();
  const { isFetchLoading, fetchLocationData, fetchLocations } = useLocation();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [locations, setLocations] = useState([]);
  const [tenantId, setTenantId] = useState(null);

  useEffect(() => {
    if (location?.state?.tenantId) setTenantId(location.state.tenantId);
  }, [location]);

  useEffect(() => {
    if (tenantId) {
      fetchLocations({
        variables: { tenantId: tenantId },
      });
    }
  }, [tenantId]);

  useEffect(() => {
    setIsLoading(isFetchLoading);
  }, [isFetchLoading]);

  useEffect(() => {
    if (fetchLocationData?.locations) {
      setLocations(fetchLocationData.locations);
    }
  }, [fetchLocationData]);

  return (
    <LocationContext.Provider
      value={{ locations: locations, setLocations: setLocations }}
    >
      <LoadingGif />
      <TenantIdProvider tenantId={tenantId}>
        {tenantId && <Router />}
      </TenantIdProvider>
    </LocationContext.Provider>
  );
};

export default App;
