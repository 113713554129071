import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import DeviceList from "../components/Device-Monitoring/DeviceList";
import Filters from "../components/Device-Monitoring/Filters";
import Order from "../components/Device-Monitoring/Order";
import SelectionOptions from "../components/Device-Monitoring/SelectionOptions";
import DeviceDialog from "../components/common/DeviceDialog";
import { useIntl } from "react-intl";
import { ACTIVE } from "../utils/constants";
import { getTelemetryData } from "../utils";
import LocationContext from "../context/LocationContext";
import {
  useDevice,
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  TenantIdContext,
  getLocationFromSiteId,
  LOCALES,
  exportToExcel,
  DarkModeContext,
  useModel,
} from "@datwyler/shared-components";

const defaultFilters = { sites: [], deviceTypes: [] };

const DeviceMonitoring = (props: any) => {
  const { tenantId }: any = useContext(TenantIdContext);
  const {
    isFetchLoading,
    fetchDeviceData,
    fetchDevices,
    isAddDeviceLoading,
    resetAddDeviceData,
    batchUpdate,
    batchCreation,
  } = useDevice();
  const {
    isFetchLoading: isFetchDeviceForExportLoading,
    fetchDeviceData: fetchDeviceDataForExport,
    fetchDevices: fetchDevicesForExport,
  } = useDevice();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [filters, setFilters] = useState(defaultFilters);
  const [isAddDeviceModalOpen, setIsAddDeviceModalOpen] = useState(false);
  const [order, setOrder] = useState("type");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [layout, setLayout] = useState("list");
  const { locations }: any = useContext(LocationContext);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [allSites, setAllSites] = useState([]);
  const { isFetchModelsLoading, fetchModelsData, fetchModels } = useModel();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    if (locations) {
      let allSites = [];
      locations.forEach((loc) => {
        allSites = allSites.concat(loc.sites);
      });

      setAllSites(allSites);
    }
  }, [locations]);

  useEffect(() => {
    setIsLoading(
      isFetchLoading ||
        isAddDeviceLoading ||
        isFetchModelsLoading ||
        isFetchDeviceForExportLoading ||
        false
    );
  }, [
    isFetchLoading,
    isAddDeviceLoading,
    isFetchModelsLoading,
    isFetchDeviceForExportLoading,
  ]);

  useEffect(() => {
    if (fetchDeviceData?.devices?.page) {
      setTotalRows(fetchDeviceData.devices.page.totalElements);
    }
  }, [fetchDeviceData]);

  useEffect(() => {
    refetchDevices();
  }, [tenantId, filters, page, rowsPerPage, order]);

  useEffect(() => {
    if (layout === "grid") setSelectedDevices([]);
  }, [layout]);

  useEffect(() => {
    if (fetchDeviceDataForExport?.devices?.devices) {
      exportToExcel(
        getExcelData(),
        intl.formatMessage({ id: "excel_filename_devices" })
      );
    }
  }, [fetchDeviceDataForExport]);

  const getFilters = () => {
    const filtersToSend = [`status:${ACTIVE}`];

    if (filters.sites.length > 0) {
      let filterSites = "";
      filters.sites.forEach((siteId, index) => {
        if (index === 0) filterSites = filterSites + `siteId:` + siteId;
        else filterSites = filterSites + `|siteId:` + siteId;
      });
      filtersToSend.push(filterSites);
    }

    if (filters.deviceTypes.length > 0) {
      let filterDevices = "";
      filters.deviceTypes.forEach((deviceType, index) => {
        if (index === 0) filterDevices = filterDevices + `type:` + deviceType;
        else filterDevices = filterDevices + `|type:` + deviceType;
      });
      filtersToSend.push(filterDevices);
    }

    return filtersToSend;
  };

  const getSort = () => {
    let sort = "";

    if (order) {
      switch (order) {
        case "status":
          sort = `status,asc`;
          break;
        case "type":
          sort = `type,asc`;
          break;
        default:
          sort = `status,asc`;
      }
    }

    return sort;
  };

  const refetchDevices = () => {
    const filtersToSend = getFilters();
    const sort = getSort();

    fetchDevices({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: [sort],
        page: page,
        size: rowsPerPage,
      },
    });
  };

  const handleAddDeviceDialog = () => {
    setIsAddDeviceModalOpen(!isAddDeviceModalOpen);
  };

  const handleSetSelectedDevices = (selections) => {
    setSelectedDevices(selections);
  };

  const getLowerComponent = () => {
    if (selectedDevices.length > 0) {
      return (
        <SelectionOptions
          layout={layout}
          setLayout={setLayout}
          batchUpdate={batchUpdate}
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
        />
      );
    } else {
      return (
        <>
          <Divider sx={{ borderColor: colors.dividerColor }} />
          <Box sx={{ display: "inline-flex" }}>
            <Filters
              sites={allSites}
              models={fetchModelsData?.models}
              defaultFilters={defaultFilters}
              setFilters={setFilters}
              layout={layout}
              handleChangeLayout={setLayout}
            />
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              verticalAlign: "top",
              paddingTop: "16px",
            }}
          >
            <Order order={order} setOrder={setOrder} />
          </Box>
        </>
      );
    }
  };

  const closeDialog = () => {
    setIsAddDeviceModalOpen(false);
  };

  const fetchDataForExcel = () => {
    const filtersToSend = getFilters();
    const sort = getSort();

    fetchDevicesForExport({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: [sort],
        page: 0,
        size: 9999999,
      },
    });
  };

  const getExcelData = () => {
    const excelData = fetchDeviceDataForExport?.devices?.devices.map((data) => {
      const row = {};

      const status = getTelemetryData(data?.telemetry, "status");
      const location = getLocationFromSiteId(locations, data.site?.id);

      row[intl.formatMessage({ id: "device_name" })] = data.name || "-";
      row[intl.formatMessage({ id: "type" })] = data.type
        ? intl.formatMessage({ id: data.type })
        : "-";
      row[intl.formatMessage({ id: "device_id" })] = data.id || "-";
      row[intl.formatMessage({ id: "location" })] = location?.name || "-";
      row[intl.formatMessage({ id: "site" })] = data.site?.name || "-";
      row[intl.formatMessage({ id: "status" })] = status
        ? intl.formatMessage({ id: status.toLowerCase() })
        : "-";

      return row;
    });
    return excelData;
  };

  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <LoadingGif />
      <BasicBreadcrumbs activePage={"DeviceMonitoring"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "devices" })}
        addButton={{
          label: intl.formatMessage({ id: "add_device" }),
          onClick: handleAddDeviceDialog,
          width:
            intl.locale === LOCALES.FRENCH || intl.locale === LOCALES.GERMAN
              ? "196px"
              : "144px",
        }}
        exportButton={{
          label: intl.formatMessage({ id: "export" }),
          onClick: fetchDataForExcel,
        }}
        LowerComponent={getLowerComponent()}
      />
      <DeviceList
        devices={fetchDeviceData?.devices?.devices}
        layout={layout}
        handleSetSelectedDevices={handleSetSelectedDevices}
        selectedDevices={selectedDevices}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={totalRows}
      />
      <DeviceDialog
        tenantId={tenantId}
        isOpen={isAddDeviceModalOpen}
        batchCreation={batchCreation}
        resetAddDeviceData={resetAddDeviceData}
        closeDialog={closeDialog}
        models={fetchModelsData?.models}
      />
    </Box>
  );
};

export default DeviceMonitoring;
