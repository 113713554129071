import { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import LocationContext from "../../../context/LocationContext";
import DeviceDialogContent from "./DeviceDialogContent";
import { useIntl } from "react-intl";
import { ACTIVE } from "../../../utils/constants";
import {
  CloseIcon24p,
  BasicButton,
  useSnackbar,
  DarkModeContext,
} from "@datwyler/shared-components";

const DeviceDialog = (props: any) => {
  const {
    isOpen,
    tenantId,
    updateDevice,
    device,
    batchCreation,
    resetAddDeviceData,
    closeDialog,
    models,
  } = props;

  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { colors }: any = useContext(DarkModeContext);

  const rules = {
    required: {
      type: "required",
      value: true,
      message: intl.formatMessage({ id: "validation_required" }),
    },
  };

  const defaultValues = {
    name: "",
    siteId: "",
    type: "",
    model: "",
    env: null,
  };

  const form = useForm({
    defaultValues: defaultValues,
  });
  const { locations }: any = useContext(LocationContext);

  const type = form.watch("type");
  const siteId = form.watch("siteId");
  const env = form.watch("env");

  const [additionalEnvironments, setAdditionalEnvironments] = useState([]);
  const [additionalEnvironmentsErrors, setAdditionalEnvironmentsErrors] =
    useState({});

  useEffect(() => {
    if (device) {
      form.reset({
        name: device.name,
        siteId: device.site?.id || "",
        type: device.type,
        model: device.model?.id,
      });
    }
  }, [device]);

  const handleClose = () => {
    if (device) {
      form.reset({
        name: device.name,
        siteId: device.site?.id || "",
        type: device.type,
        model: device.model?.id,
      });
    } else {
      form.reset(defaultValues);
    }
    closeDialog();
  };

  const handleRegister = async () => {
    let isValid = await form.trigger();
    isValid = isValid && checkAdditionalEnvironmentsErrors();

    if (isValid) {
      const submitValues = form.getValues();
      transformBeforeSend(submitValues);

      if (updateDevice) {
        updateDevice({
          variables: { input: submitValues },
        });
      } else {
        // batch add
        const allSubmitValues = [];
        allSubmitValues.push(submitValues);

        additionalEnvironments.forEach((env) => {
          transformBeforeSend(env);
          allSubmitValues.push(env);
        });

        const allResponses = await batchCreation(allSubmitValues);

        allResponses.map((response) => {
          if (response?.data?.addDevice?.device) {
            enqueueSnackbar(
              intl.formatMessage({ id: "new_device_added" }) +
                ": " +
                response.data.addDevice.device.name,
              {
                variant: "success",
              }
            );

            setAdditionalEnvironments([]);
            resetAddDeviceData();
            handleClose();
          } else {
            enqueueSnackbar(intl.formatMessage({ id: "error" }), {
              variant: "error",
            });
          }
        });
      }
    }
  };

  const transformBeforeSend = (submitValues) => {
    if (updateDevice) {
      // Update
      delete submitValues.model;
      delete submitValues.type;
      submitValues.id = device.id;
      submitValues.id = device.id;
      submitValues.status = device.status;
    } else {
      // Add
      submitValues.tenant = { id: tenantId };
      submitValues.site = { id: siteId };
      if (submitValues.type === "Environment")
        submitValues.type = submitValues.env;

      submitValues.status = ACTIVE;
      submitValues.model = { id: submitValues.model };
    }

    delete submitValues.siteId;
    delete submitValues.env;
  };

  const checkAdditionalEnvironmentsErrors = () => {
    const errors = {};
    additionalEnvironments.forEach((env, index) => {
      if (!env.name) {
        errors["additional_" + index + "_name"] = rules.required;
      }

      if (!env.model) {
        errors["additional_" + index + "_model"] = rules.required;
      }

      if (!env.type) {
        errors["additional_" + index + "_type"] = rules.required;
      }
    });

    setAdditionalEnvironmentsErrors(errors);
    return Object.keys(errors).length < 1;
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={"sm"}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            minHeight: batchCreation ? "430px" : "230px",
            maxHeight: "90vh",
            marginTop: 0,
            backgroundColor: colors.cardBg,
          },
        }}
        sx={{
          "&& .MuiDialog-container": {
            alignItems: "unset",
            marginTop: "5vh",
            height: "unset",
          },
        }}
      >
        <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-SemiBold",
              fontSize: 16,
              color: colors.subTitle,
            }}
          >
            {updateDevice
              ? intl.formatMessage({ id: "update_device" })
              : intl.formatMessage({ id: "add_device" })}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 16, top: 16 }}
          >
            <CloseIcon24p fill={colors.iconColor} />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <DeviceDialogContent
          form={form}
          isAddDevice={!!batchCreation}
          locations={locations}
          models={models}
          type={type}
          env={env}
          additionalEnvironments={additionalEnvironments}
          setAdditionalEnvironments={setAdditionalEnvironments}
          additionalEnvironmentsErrors={additionalEnvironmentsErrors}
          setAdditionalEnvironmentsErrors={setAdditionalEnvironmentsErrors}
          rules={rules}
        />
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            paddingBottom: "24px",
            paddingRight: "24px",
          }}
        >
          <Box sx={{ marginRight: "16px" }}>
            <BasicButton onClick={handleClose} variant={"outlined"}>
              {intl.formatMessage({ id: "cancel" })}
            </BasicButton>
          </Box>
          <Box>
            <BasicButton onClick={handleRegister} variant={"contained"}>
              {updateDevice
                ? intl.formatMessage({ id: "update_device" })
                : intl.formatMessage({ id: "add_device" })}
            </BasicButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeviceDialog;
