import { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import {
  SelectDisplayProps,
  inputLabelSx,
  menuPaperSx,
  inputLabelProps,
  inputProps,
} from "../../../utils/constants";
import { getTelemetryData } from "../../../utils";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  useSnackbar,
  BasicSelectWithCheckMark,
  BasicTextField,
} from "@datwyler/shared-components";

const ip_regex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

const protocolOptions = [
  { priority: 1, value: "STATIC", label: "Static" },
  { priority: 2, value: "DHCT", label: "DHCT" },
  { priority: 3, value: "DHCTV6", label: "DHCTv6" },
  { priority: 4, value: "PPPoE", label: "PPPoE" },
  { priority: 5, value: "MOBILE", label: "Mobile" },
];

const formDefaultValues = {
  network_lan_protocol: "STATIC",
  network_lan_ipaddr: "",
  network_lan_netmask: "",
  network_lan_gateway: "",
  network_lan_broadcast: "",
  dhcp_lan_start: "",
  endIp: "",
  leaseTime: "",
  timeUnit: "m",
};

const getEndIp = (startIp, limit) => {
  if (!startIp || !limit) return "";
  return (parseInt(startIp) + parseInt(limit)).toString();
};

const getLimit = (startIp, endIp) => {
  if (!startIp || !endIp) return "";
  return (parseInt(endIp) - parseInt(startIp)).toString();
};

const UpdateLanSettingDialog = (props) => {
  const {
    isOpen = false,
    device,
    closeDialog,
    updateLanSetting,
    updateLanSettingResponseData,
    tenantId,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const { colors }: any = useContext(DarkModeContext);

  const form = useForm({
    defaultValues: formDefaultValues,
  });

  const ipAddress = form.watch("network_lan_ipaddr");

  useEffect(() => {
    if (device) {
      const dhcpLanStart = getTelemetryData(
        device?.telemetry,
        "dhcp_lan_start"
      );
      const dhcpLanLimit = getTelemetryData(
        device?.telemetry,
        "dhcp_lan_limit"
      );
      const dhcpLeaseTime = getTelemetryData(
        device?.telemetry,
        "dhcp_lan_leasetime"
      );
      form.reset({
        network_lan_protocol:
          getTelemetryData(device?.telemetry, "network_lan_protocol") ||
          formDefaultValues.network_lan_protocol,
        network_lan_ipaddr:
          getTelemetryData(device?.telemetry, "network_lan_ipaddr") ||
          formDefaultValues.network_lan_ipaddr,
        network_lan_netmask:
          getTelemetryData(device?.telemetry, "network_lan_netmask") ||
          formDefaultValues.network_lan_netmask,
        network_lan_gateway:
          getTelemetryData(device?.telemetry, "network_lan_gateway") ||
          formDefaultValues.network_lan_gateway,
        network_lan_broadcast:
          getTelemetryData(device?.telemetry, "network_lan_broadcast") ||
          formDefaultValues.network_lan_broadcast,
        dhcp_lan_start: dhcpLanStart || formDefaultValues.dhcp_lan_start,
        endIp: getEndIp(dhcpLanStart, dhcpLanLimit) || formDefaultValues.endIp,
        leaseTime: dhcpLeaseTime?.replace(/\D/g, "") || formDefaultValues.endIp,
        timeUnit:
          dhcpLeaseTime?.replace(/[^A-Za-z]/g, "") ||
          formDefaultValues.timeUnit,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (form?.formState) {
      if (Object.keys(form.formState.dirtyFields).length > 0) {
        setIsSubmitDisabled(false);
      } else {
        setIsSubmitDisabled(true);
      }
    }
  }, [form.formState]);

  useEffect(() => {
    if (updateLanSettingResponseData?.updateLanSetting?.status === "Executed") {
      enqueueSnackbar(intl.formatMessage({ id: "update_setting_success" }), {
        variant: "success",
      });
      closeDialog();
    }
  }, [updateLanSettingResponseData]);

  const handleSave = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      const submitValues = form.getValues();
      updateLanSetting({
        variables: {
          input: {
            tenant: { id: tenantId },
            device: { id: device.id },
            commands: getCommands(submitValues),
          },
        },
      });
    }
  };

  const getCommands = (submitValues) => {
    const commands = [];

    // Lan Settings
    if (submitValues.network_lan_protocol)
      commands.push(
        "network.lan.protocol=" + submitValues.network_lan_protocol
      );
    if (submitValues.network_lan_ipaddr)
      commands.push("network.lan.ipaddr=" + submitValues.network_lan_ipaddr);
    if (submitValues.network_lan_netmask)
      commands.push("network.lan.netmask=" + submitValues.network_lan_netmask);
    if (submitValues.network_lan_gateway)
      commands.push("network.lan.gateway=" + submitValues.network_lan_gateway);
    if (submitValues.network_lan_broadcast)
      commands.push(
        "network.lan.netmask=" + submitValues.network_lan_broadcast
      );

    // DHCP Settings
    if (submitValues.dhcp_lan_start)
      commands.push("dhcp.lan.start=" + submitValues.dhcp_lan_start);
    if (submitValues.dhcp_lan_start && submitValues.endIp)
      commands.push(
        "dhcp.lan.limit=" +
          getLimit(submitValues.dhcp_lan_start, submitValues.endIp)
      );
    if (submitValues.leaseTime && submitValues.timeUnit)
      commands.push(
        "dhcp.lan.leasetime=" + submitValues.leaseTime + submitValues.timeUnit
      );

    return commands;
  };

  const timeUnitOptions = [
    { priority: 0, value: "s", label: intl.formatMessage({ id: "seconds" }) },
    { priority: 1, value: "m", label: intl.formatMessage({ id: "minutes" }) },
    { priority: 2, value: "h", label: intl.formatMessage({ id: "hours" }) },
  ];

  const rulesIP = {
    pattern: {
      value: ip_regex,
      message: intl.formatMessage({ id: "invalid_ip" }),
    },
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "update_lan_setting" })}
        </Typography>
        <IconButton
          onClick={closeDialog}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent sx={{ overflowX: "hidden" }}>
        <Box sx={{ width: "376px" }}>
          <Box>
            <BasicSelectWithCheckMark
              menuItems={protocolOptions}
              form={form}
              formItemLabel={"network_lan_protocol"}
              label={intl.formatMessage({ id: "select_protocol" })}
              SelectDisplayProps={SelectDisplayProps}
              inputLabelSx={inputLabelSx}
              menuPaperSx={menuPaperSx}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "ipv4_address" })}
              type={"text"}
              form={form}
              formItemLabel={"network_lan_ipaddr"}
              rules={rulesIP}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "enter_ipv4_netmask" })}
              type={"text"}
              form={form}
              formItemLabel={"network_lan_netmask"}
              rules={rulesIP}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "enter_ipv4_netmask" })}
              type={"text"}
              form={form}
              formItemLabel={"network_lan_gateway"}
              rules={rulesIP}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "enter_ipv4_gateway" })}
              type={"text"}
              form={form}
              formItemLabel={"network_lan_broadcast"}
              rules={rulesIP}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </Box>
        </Box>
        <Divider sx={{ marginTop: "24px", borderColor: colors.dividerColor }} />
        <Box sx={{ marginTop: "24px" }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-SemiBold",
              fontSize: 16,
              color: colors.subTitle,
            }}
          >
            {intl.formatMessage({ id: "dhcp_server_setting" })}
          </Typography>
          <Box sx={{ marginTop: "10px" }}>
            <Box sx={{ display: "inline-flex", minWidth: "80px" }}>
              <Typography
                sx={{
                  fontFamily: "NotoSans-SemiBold",
                  fontSize: 14,
                  color: colors.updateLanDefaultIPFont,
                  fontWeight: 500,
                  letterSpacing: 0,
                  lineHeight: "20px",
                }}
              >
                {ipAddress.substring(0, ipAddress.lastIndexOf(".") + 1)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                width: "132px",
                marginLeft: "16px",
              }}
            >
              <BasicTextField
                label={intl.formatMessage({ id: "start_ip" })}
                type={"text"}
                form={form}
                formItemLabel={"dhcp_lan_start"}
                variant={"outlined"}
                size={"small"}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
              />
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                marginLeft: "4px",
                marginRight: "4px",
                color: colors.blueGray300,
              }}
            >
              -
            </Box>
            <Box sx={{ display: "inline-flex", width: "132px" }}>
              <BasicTextField
                label={intl.formatMessage({ id: "end_ip" })}
                type={"text"}
                form={form}
                formItemLabel={"endIp"}
                variant={"outlined"}
                size={"small"}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                rules={{
                  validate: {
                    end_ip_smaller: (value) => {
                      const startIp = form.getValues("dhcp_lan_start");
                      if (startIp) {
                        return parseInt(value) > parseInt(startIp)
                          ? true
                          : intl.formatMessage({
                              id: "validation_end_ip_smaller",
                            });
                      } else {
                        return true;
                      }
                    },
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: "24px" }}>
            <Box sx={{ display: "inline-flex", width: "228px" }}>
              <BasicTextField
                label={intl.formatMessage({ id: "enter_lease_time" })}
                type={"text"}
                form={form}
                formItemLabel={"leaseTime"}
                variant={"outlined"}
                size={"small"}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
              />
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                width: "132px",
                marginLeft: "16px",
              }}
            >
              <BasicSelectWithCheckMark
                menuItems={timeUnitOptions}
                form={form}
                formItemLabel={"timeUnit"}
                SelectDisplayProps={SelectDisplayProps}
                inputLabelSx={inputLabelSx}
                menuPaperSx={menuPaperSx}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", paddingBottom: "24px" }}>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={closeDialog} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={handleSave}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
            disabled={isSubmitDisabled}
          >
            {intl.formatMessage({ id: "save_changes" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLanSettingDialog;
