import { useState, useEffect, useContext } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { getTelemetryData } from "../../../../utils";
import {
  DarkModeContext,
  ConnectionIcon,
  getRequiredDateFormat,
  LOCALES,
  BasicCard,
} from "@datwyler/shared-components";

const LastConnection = (props: any) => {
  const { cardHeight, deviceDetails } = props;
  const [status, setStatus] = useState("");
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    const data = getTelemetryData(deviceDetails, "last_connection");
    setStatus(getValue(data));
  }, [deviceDetails]);

  const getValue = (data) => {
    return data
      ? getRequiredDateFormat(parseInt(data), "DD-MM-YYYY HH:mm:ss")
      : intl.formatMessage({ id: "not_available" });
  };

  return (
    <BasicCard sx={{ height: cardHeight, boxShadow: "none", padding: 0 }}>
      <CardContent style={{ padding: "10px 12px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              height: 10,
              width: 20,
              padding: "15px 10px",
              borderRadius: "20px",
              backgroundColor: colors.blue50,
            }}
          >
            <ConnectionIcon />
          </Box>
        </Box>
        <Box sx={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "inline-flex", flexGrow: 1 }}>
            <Typography
              sx={{
                color: colors.deviceStatusValue,
                fontFamily: "NotoSans-Regular",
                fontSize:
                  intl.locale === LOCALES.FRENCH ||
                  intl.locale === LOCALES.GERMAN
                    ? "14px"
                    : "16px",
                letterSpacing: "0.02px",
                lineHeight: "24px",
              }}
            >
              {intl.formatMessage({ id: "last_connection" })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
            }}
          >
            <Typography
              sx={{
                color: colors.deviceStatusInput,
                fontFamily: "NotoSans-SemiBold",
                fontSize: "16px",
                fontWeight: "600",
                letterSpacing: "0.02px",
                lineHeight: "17px",
              }}
            >
              {status}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </BasicCard>
  );
};

export default LastConnection;
