import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import {
  DarkModeContext,
  ApiContext,
  useSnackbar,
  MoreButton,
  BasicBreadcrumbs,
} from "@datwyler/shared-components";
import { copyToClipboard, isShowGatewayScreen } from "../../utils";

const DeviceTopComponent = (props: any) => {
  const { device, openDialog, tenantId } = props;
  const intl = useIntl();
  const { uri, apiInstance }: any = useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();
  const { colors }: any = useContext(DarkModeContext);

  const copyDeviceIdMenuItem = {
    label: intl.formatMessage({ id: "copy_device_id" }),
    value: null,
    onClick: () => copyToClipboard(device, enqueueSnackbar, intl),
  };

  const updateDeviceMenuItem = {
    label: intl.formatMessage({ id: "update_device" }),
    value: null,
    onClick: () => openDialog("DeviceDialog"),
  };

  const deleteDeviceMenuItem = {
    label: intl.formatMessage({ id: "delete_device" }),
    value: null,
    onClick: () => openDialog("DeleteDeviceDialog"),
    menuItemFontStyle: {
      color: colors.red800,
      fontFamily: "NotoSans-Regular",
      fontSize: "14px",
      letterSpacing: 0,
      lineHeight: "20px",
      "&:hover": {
        color: colors.red800,
      },
    },
  };

  const alarmRuleMenuItem = {
    label: intl.formatMessage({ id: "alarm_rule" }),
    value: device,
    onClick: () => openDialog("AlarmRuleDialog"),
  };

  const downloadInstaller = () => {
    const url =
      uri + "/tenants/" + tenantId + "/devices/" + device.id + "/installer";
    const extension = ".sh";

    const text2Binary = (string) => {
      var length, array;
      length = string.length;
      array = new Uint8Array(length);
      for (var i = 0; i < length; i++) {
        array[i] = string.charCodeAt(i);
      }
      return array;
    };

    const downloadFile = (fileName, file) => {
      const aElement = document.createElement("a");
      aElement.setAttribute("download", fileName);
      const href = URL.createObjectURL(file);
      aElement.href = href;
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    };

    apiInstance
      .get(url)
      .then((response) => {
        if (response?.data) {
          const file = new Blob([new Uint8Array(text2Binary(response.data))]);
          const fileName = "installer" + extension;
          downloadFile(fileName, file);
        } else {
          enqueueSnackbar(intl.formatMessage({ id: "error" }), {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  const gatewayOptions = [
    copyDeviceIdMenuItem,
    {
      label: "seperator",
    },
    {
      label: intl.formatMessage({ id: "restart" }),
      value: device,
      onClick: () => openDialog("RestartDialog"),
    },
    {
      label: intl.formatMessage({ id: "update_firmware" }),
      value: device,
      onClick: () => openDialog("UpdateFirmwareDialog"),
    },
    alarmRuleMenuItem,
    {
      label: "seperator",
    },
    {
      label: intl.formatMessage({ id: "update_lan_setting" }),
      value: device,
      onClick: () => openDialog("UpdateLanSettingDialog"),
      menuItemFontStyle: {
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      label: intl.formatMessage({ id: "download_installer_sh" }),
      value: device,
      onClick: downloadInstaller,
      menuItemFontStyle: {
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      label: "seperator",
    },
    updateDeviceMenuItem,
    deleteDeviceMenuItem,
  ];

  const getOptions = () => {
    if (isShowGatewayScreen(device?.model?.id)) {
      return gatewayOptions;
    } else {
      return [copyDeviceIdMenuItem, updateDeviceMenuItem, deleteDeviceMenuItem];
    }
  };

  return (
    <Box>
      <BasicBreadcrumbs activePage={"DeviceDetails"} device={device} />
      <Box sx={{ display: "inline-flex", marginTop: "16px" }}>
        <Typography
          sx={{
            color: colors.fontTitle,
            fontFamily: "NotoSans-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            letterSpacing: "0",
            lineHeight: "34px",
          }}
        >
          {device.name}
        </Typography>
      </Box>
      <Box sx={{ display: "inline-flex", position: "absolute", right: "24px" }}>
        <MoreButton
          options={getOptions()}
          paperProps={{ style: { width: "218px" } }}
          transformOrigin={{
            vertical: -8,
            horizontal: 132,
          }}
          buttonSx={{
            border: " 0.7px solid " + colors.blueGray300,
            width: "48px",
            height: "44px",
          }}
        />
      </Box>
    </Box>
  );
};

export default DeviceTopComponent;
