import { useState, useEffect, useContext } from "react";
import { ResponsiveLine } from "@nivo/line";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getTemperatureData } from "../../../utils";
import { useIntl } from "react-intl";
import {
  getRequiredDateFormat,
  BasicCard,
  DarkModeContext,
} from "@datwyler/shared-components";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const Temperature = (props: any) => {
  const { data = [], device } = props;
  const [temperatureData, setTemperatureData] = useState([]);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (device && data && data.length > 0)
      setTemperatureData(getTemperatureData(data, device));
    else if (temperatureData?.length !== 0) setTemperatureData([]);
  }, [data, device]);

  const getTickValues = () => {
    const values = [];
    if (temperatureData[0]?.data) {
      const numTicks = 10;
      const max = temperatureData[0].data.length;
      const intervals = Math.floor(max / numTicks);

      if (intervals < 1) {
        temperatureData[0].data.forEach((d) => {
          values.push(d?.x);
        });
      } else {
        for (let i = 0; i < max; i = i + intervals) {
          values.push(temperatureData[0].data[i].x);
        }
      }
    }

    return values;
  };

  const theme = {
    axis: {
      ticks: {
        line: {
          stroke: colors.chartAxisTick,
        },
        text: {
          fill: colors.chartAxisFont,
        },
      },
      legend: {
        text: {
          fill: colors.chartAxisFont,
        },
      },
    },
    legends: {
      text: {
        fill: colors.chartAxisFont,
      },
    },
  };

  return (
    <BasicCard
      sx={{ width: "100%", height: 340, marginTop: "24px", padding: 0 }}
    >
      <CardContent sx={{ height: 300 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: colors.deviceCardHeaderFont }}
        >
          {intl.formatMessage({ id: "temperature" }) + " °C"}
        </Typography>
        <ResponsiveLine
          data={temperatureData}
          enablePoints={false}
          enableGridX={false}
          margin={{ top: 10, right: 20, bottom: 90, left: 50 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: -45,
            legend: intl.formatMessage({ id: "time" }),
            legendOffset: 45,
            legendPosition: "end",
            tickValues: getTickValues(),
            format: (values) => `${getRequiredDateFormat(values, "HH:mm")}`, // using momentjs to format
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: intl.formatMessage({ id: "temperature" }) + " °C",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          theme={theme}
        />
      </CardContent>
    </BasicCard>
  );
};

export default Temperature;
