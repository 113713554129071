import DevicePowerStatus from "./Cards/DevicePowerStatus";
import SimCard from "./Cards/SimCard";
import LastConnection from "./Cards/LastConnection";
import Firmware from "./Cards/Firmware";
import DeviceInformation from "./Cards/DeviceInformation";
import SignalGauge from "./Cards/SignalGauge";
import Grid from "@mui/material/Grid";
import { isShowGatewayScreen } from "../../../utils";

const DeviceStatusCards = (props) => {
  const { device } = props;
  const cardHeight = "104px";
  const cardHeightLarge = "312px";

  if(isShowGatewayScreen(device?.model?.id)) {
    return (
      <>
        <Grid container spacing={1} sx={{ marginTop: "24px" }}>
          <Grid item xs={1.75}>
            <DevicePowerStatus
              cardHeight={cardHeight}
              deviceDetails={device.telemetry}
            />
          </Grid>
          <Grid item xs={2.25}>
            <SimCard cardHeight={cardHeight} deviceDetails={device.telemetry} />
          </Grid>
          <Grid item xs={4}>
            <LastConnection
              cardHeight={cardHeight}
              deviceDetails={device.telemetry}
            />
          </Grid>
          <Grid item xs={4}>
            <Firmware
              cardHeight={cardHeight}
              deviceDetails={device.telemetry}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: "24px", display: "flex" }}>
          <Grid item xs={6}>
            <DeviceInformation cardHeight={cardHeightLarge} device={device} />
          </Grid>
          <Grid item xs={6}>
            <SignalGauge cardHeight={cardHeightLarge} device={device} />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <Grid container spacing={2} sx={{ marginTop: "24px" }}>
        <Grid item xs={2}>
          <DevicePowerStatus
            cardHeight={cardHeight}
            deviceDetails={device.telemetry}
          />
        </Grid>
        <Grid item xs={4}>
          <LastConnection
            cardHeight={cardHeight}
            deviceDetails={device.telemetry}
          />
        </Grid>
      </Grid>
    );
  }
};

export default DeviceStatusCards;
