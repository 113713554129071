import { useState, useMemo, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useIntl } from "react-intl";
import {
  MAXCHAR_NAME,
  SelectDisplayProps,
  inputLabelSx,
  menuPaperSx,
  getEnvironmentOptions,
} from "../../../utils/constants";
import {
  CloseIcon24p,
  BasicTextFieldWithoutForm,
  BasicSelectWithoutForm,
  DarkModeContext,
} from "@datwyler/shared-components";

const EnvironmentComponent = (props) => {
  const {
    additionalEnvironments,
    setAdditionalEnvironments,
    index,
    rules,
    models,
    errors,
    setErrors,
  } = props;
  const intl = useIntl();
  const [modelOptions, setModelOptions] = useState([]);
  const env = additionalEnvironments[index];
  const { colors }: any = useContext(DarkModeContext);

  useMemo(() => {
    const modelOptions = [];
    let count = 0;
    if (models) {
      if (env) {
        models.forEach((model) => {
          if (model.deviceType === env.type) {
            modelOptions.push({
              priority: count++,
              label: model.name,
              value: model.id,
            });
          }
        });
      }
    }
    setModelOptions(modelOptions);
  }, [models, env]);

  const handleChangeType = (event) => {
    const val = event.target.value;
    const newEnvs = JSON.parse(JSON.stringify(additionalEnvironments));
    newEnvs[index].type = val;
    setAdditionalEnvironments(newEnvs);

    const error = errors["additional_" + index + "_type"];
    if (error?.type === "required") {
      if (val) {
        const temp = JSON.parse(JSON.stringify(errors));
        delete temp["additional_" + index + "_type"];
        setErrors(temp);
      }
    }
  };

  const handleChangeModel = (event) => {
    const val = event.target.value;
    const newEnvs = JSON.parse(JSON.stringify(additionalEnvironments));
    newEnvs[index].model = val;
    setAdditionalEnvironments(newEnvs);

    const error = errors["additional_" + index + "_model"];
    if (error?.type === "required") {
      if (val) {
        const temp = JSON.parse(JSON.stringify(errors));
        delete temp["additional_" + index + "_model"];
        setErrors(temp);
      }
    }
  };

  const handleOnChangeName = (val) => {
    const newEnvs = JSON.parse(JSON.stringify(additionalEnvironments));
    newEnvs[index].name = val;
    setAdditionalEnvironments(newEnvs);

    const error = errors["additional_" + index + "_name"];

    if (val.length > MAXCHAR_NAME) {
      errors["additional_" + index + "_name"] = {
        type: "maxLength",
        message: intl.formatMessage({ id: "validation_maxlength" }),
      };
    } else {
      if (
        (error?.type === "maxLength" && val.length < MAXCHAR_NAME) ||
        (val && error?.type === "required")
      ) {
        const temp = JSON.parse(JSON.stringify(errors));
        delete temp["additional_" + index + "_name"];
        setErrors(temp);
      }
    }
  };

  const handleRemove = () => {
    const newEnvs = JSON.parse(JSON.stringify(additionalEnvironments));
    newEnvs.splice(index, 1);
    setAdditionalEnvironments(newEnvs);
  };

  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: colors.envComponentBg,
        marginTop: "21px",
      }}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ display: "inline-flex", height: "24px" }}>
          <Typography
            sx={{
              color: colors.subTitle,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: "0.01px",
              lineHeight: "24px",
              display: "inline-flex",
              width: "320px",
            }}
          >
            {intl.formatMessage({ id: "env_type" }) + " " + (index + 2)}
          </Typography>
          <Tooltip
            title={intl.formatMessage({ id: "remove" })}
            arrow
            placement={"bottom-end"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: colors.subtitleFont,
                  color: colors.disabledButtonFont,
                  fontFamily: "NotoSans-Medium",
                  fontSize: "16px",
                  fontWeight: 500,
                  letterSpacing: "0.01px",
                  lineHeight: "17px",
                  padding: "5px 12px",
                },
              },
            }}
          >
            <IconButton
              onClick={handleRemove}
              sx={{ display: "inline-flex", py: "4px", px: "2px" }}
            >
              <CloseIcon24p alt={"close"} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        <BasicSelectWithoutForm
          menuItems={getEnvironmentOptions()}
          formItemLabel={"additional_" + index + "_type"}
          label={intl.formatMessage({ id: "type_of_env" })}
          rules={rules}
          SelectDisplayProps={SelectDisplayProps}
          inputLabelSx={inputLabelSx}
          menuPaperSx={menuPaperSx}
          onChange={handleChangeType}
          value={env.type}
          error={errors["additional_" + index + "_type"]}
        />
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        <BasicSelectWithoutForm
          menuItems={modelOptions}
          formItemLabel={"additional_" + index + "_model"}
          label={intl.formatMessage({ id: "select_model" })}
          rules={rules}
          SelectDisplayProps={SelectDisplayProps}
          inputLabelSx={inputLabelSx}
          menuPaperSx={menuPaperSx}
          onChange={handleChangeModel}
          value={env.model}
          error={errors["additional_" + index + "_model"]}
        />
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        <BasicTextFieldWithoutForm
          label={intl.formatMessage({ id: "enter_device_name" })}
          type={"text"}
          formItemLabel={"additional_" + index + "_name"}
          handleOnChange={handleOnChangeName}
          rules={rules}
          variant={"outlined"}
          size={"small"}
          InputLabelProps={{
            sx: {
              fontSize: "14px",
              letterSpacing: "0.01px",
              top: "3px",
            },
          }}
          InputProps={{
            sx: {
              height: "44px",
            },
          }}
          maxChars={MAXCHAR_NAME}
          value={env.name}
          errors={errors}
        />
      </Box>
    </Box>
  );
};

export default EnvironmentComponent;
