import { useContext, useMemo, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import {
  ENIVIRONMENT_DEVICES,
  MAXCHAR_NAME,
  SelectDisplayProps,
  inputLabelSx,
  menuPaperSx,
  getEnvironmentOptions,
} from "../../../utils/constants";
import EnvironmentComponent from "./EnvironmentComponent";
import {
  BasicTextField,
  BasicSelectWithCheckMark,
  DarkModeContext,
  AddIcon,
} from "@datwyler/shared-components";

const DeviceDialogContent = (props) => {
  const {
    form,
    isAddDevice,
    locations,
    models,
    type,
    env,
    additionalEnvironments,
    setAdditionalEnvironments,
    additionalEnvironmentsErrors,
    setAdditionalEnvironmentsErrors,
    rules,
  } = props;
  const intl = useIntl();
  const [siteOptions, setSiteOptions] = useState([]);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const { colors }: any = useContext(DarkModeContext);

  useMemo(() => {
    let allSites = [];
    if (locations) {
      locations.forEach((location) => {
        if (location.sites) allSites = allSites.concat(location.sites);
      });

      const siteOptions = allSites.map((site, index) => {
        return {
          priority: index,
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(siteOptions);
    }
  }, [locations]);

  useMemo(() => {
    const deviceTypes = [];
    const deviceTypeOptions = [];
    if (models) {
      models.forEach((model) => {
        if (
          !ENIVIRONMENT_DEVICES.includes(model.deviceType) &&
          !deviceTypes.includes(model.deviceType)
        ) {
          deviceTypes.push(model.deviceType);
        }
      });

      deviceTypes.forEach((type, index) => {
        deviceTypeOptions.push({
          priority: index,
          label: type,
          value: type,
        });
      });

      deviceTypeOptions.push({
        priority: deviceTypes.length,
        label: intl.formatMessage({ id: "env" }),
        value: "Environment",
      });
    }

    setDeviceTypeOptions(deviceTypeOptions);
  }, [models]);

  useMemo(() => {
    const modelOptions = [];
    let count = 0;
    if (models) {
      if (type !== "Environment") {
        models.forEach((model) => {
          if (model.deviceType === type) {
            modelOptions.push({
              priority: count++,
              label: model.name,
              value: model.id,
            });
          }
        });
      } else if (env) {
        models.forEach((model) => {
          if (model.deviceType === env) {
            modelOptions.push({
              priority: count++,
              label: model.name,
              value: model.id,
            });
          }
        });
      }
    }
    setModelOptions(modelOptions);
  }, [type, models, env]);

  const handleAddMoreEnvironment = () => {
    const newEnvs = JSON.parse(JSON.stringify(additionalEnvironments));
    newEnvs.push({
      type: "",
      model: "",
      name: "",
    });

    setAdditionalEnvironments(newEnvs);
  };

  const handleOnChangeName = (val) => {
    if (val.length > MAXCHAR_NAME) {
      form.setError("name", {
        type: "maxLength",
        message: intl.formatMessage({ id: "validation_maxlength" }),
      });
    } else {
      form.clearErrors("name");
    }
  };

  return (
    <DialogContent>
      <Box sx={{ width: "376px" }}>
        {isAddDevice && (
          <>
            <Box>
              <BasicSelectWithCheckMark
                menuItems={siteOptions}
                form={form}
                formItemLabel={"siteId"}
                label={intl.formatMessage({ id: "select_site" })}
                rules={rules}
                SelectDisplayProps={SelectDisplayProps}
                inputLabelSx={inputLabelSx}
                menuPaperSx={menuPaperSx}
              />
            </Box>
            <Box sx={{ marginTop: "16px" }}>
              <BasicSelectWithCheckMark
                menuItems={deviceTypeOptions}
                form={form}
                formItemLabel={"type"}
                label={intl.formatMessage({ id: "select_type_of_device" })}
                rules={rules}
                SelectDisplayProps={SelectDisplayProps}
                inputLabelSx={inputLabelSx}
                menuPaperSx={menuPaperSx}
              />
            </Box>
            {type === "Environment" && (
              <Box sx={{ marginTop: "16px" }}>
                <BasicSelectWithCheckMark
                  menuItems={getEnvironmentOptions()}
                  form={form}
                  formItemLabel={"env"}
                  label={intl.formatMessage({ id: "type_of_env" })}
                  rules={rules}
                  SelectDisplayProps={SelectDisplayProps}
                  inputLabelSx={inputLabelSx}
                  menuPaperSx={menuPaperSx}
                />
              </Box>
            )}
            <Box sx={{ marginTop: "16px" }}>
              <BasicSelectWithCheckMark
                menuItems={modelOptions}
                form={form}
                formItemLabel={"model"}
                label={intl.formatMessage({ id: "select_model" })}
                rules={rules}
                SelectDisplayProps={SelectDisplayProps}
                inputLabelSx={inputLabelSx}
                menuPaperSx={menuPaperSx}
              />
            </Box>
          </>
        )}
        <Box sx={{ marginTop: "16px" }}>
          <BasicTextField
            label={intl.formatMessage({ id: "enter_device_name" })}
            type={"text"}
            form={form}
            formItemLabel={"name"}
            handleOnChange={handleOnChangeName}
            rules={rules}
            variant={"outlined"}
            size={"small"}
            InputLabelProps={{
              sx: {
                fontSize: "14px",
                letterSpacing: "0.01px",
                top: "3px",
              },
            }}
            InputProps={{
              sx: {
                height: "44px",
              },
            }}
            maxChars={MAXCHAR_NAME}
          />
        </Box>
        {isAddDevice && type === "Environment" && (
          <Box sx={{ marginTop: "20px" }}>
            <Divider sx={{ borderColor: colors.dividerColor }} />
            {additionalEnvironments.map((env, index) => {
              return (
                <EnvironmentComponent
                  additionalEnvironments={additionalEnvironments}
                  setAdditionalEnvironments={setAdditionalEnvironments}
                  index={index}
                  rules={rules}
                  models={models}
                  errors={additionalEnvironmentsErrors}
                  setErrors={setAdditionalEnvironmentsErrors}
                />
              );
            })}
            <Box
              sx={{ display: "flex", alignItems: "start", marginTop: "30px" }}
            >
              <Button
                sx={{ textTransform: "unset" }}
                onClick={handleAddMoreEnvironment}
              >
                <Box sx={{ display: "inline-flex" }}>
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "10px",
                      backgroundColor: colors.datwylerTeal,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddIcon />
                  </Box>
                </Box>
                <Box sx={{ display: "inline-flex", marginLeft: "8px" }}>
                  <Typography
                    sx={{
                      color: colors.datwylerTeal,
                      fontFamily: "NotoSans-Medium",
                      fontSize: "14px",
                      fontWeight: 500,
                      letterSpacing: 0,
                    }}
                  >
                    {intl.formatMessage({ id: "add_an_env" })}
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </DialogContent>
  );
};

export default DeviceDialogContent;
