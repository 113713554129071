import { useContext } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {
  DarkModeContext,
  ListIcon,
  GridIcon,
} from "@datwyler/shared-components";

const LayoutSelect = (props) => {
  const { layout, handleChangeLayout } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Box sx={{ display: "inline-flex", position: "absolute", right: "48px" }}>
      <IconButton
        onClick={() => handleChangeLayout("list")}
        sx={{
          display: "inline-flex",
          width: "48px",
          height: "44px",
          border:
            layout !== "list"
              ? "0.7px solid " + colors.layoutBtnBorder
              : "unset",
          borderRadius: "8px",
          backgroundColor:
            layout === "list" ? colors.layoutBtnSelected : "unset",
          "&:hover": {
            backgroundColor:
              layout === "list"
                ? colors.layoutBtnHover
                : colors.layoutBtnHover + "33",
          },
        }}
      >
        <ListIcon />
      </IconButton>
      <IconButton
        onClick={() => handleChangeLayout("grid")}
        sx={{
          display: "inline-flex",
          width: "48px",
          height: "44px",
          marginLeft: "24px",
          border:
            layout !== "grid"
              ? "0.7px solid " + colors.layoutBtnBorder
              : "unset",
          borderRadius: "8px",
          backgroundColor:
            layout === "grid" ? colors.layoutBtnSelected : "unset",
          "&:hover": {
            backgroundColor:
              layout === "grid"
                ? colors.layoutBtnHover
                : colors.layoutBtnHover + "33",
          },
        }}
      >
        <GridIcon />
      </IconButton>
    </Box>
  );
};

export default LayoutSelect;
