import { Route, Routes } from "react-router-dom";
import DeviceMonitoring from "./containers/Device-Monitoring";
import DeviceDetails from "./containers/DeviceDetails";
import { CommonStyles } from "@datwyler/shared-components";

const Router = (props: any) => {
  return (
    <Routes>
      <Route
        path="/device-monitoring/deviceDetails"
        element={
          <CommonStyles>
            <DeviceDetails />
          </CommonStyles>
        }
      />
      <Route
        path="/device-monitoring"
        element={
          <CommonStyles>
            <DeviceMonitoring />
          </CommonStyles>
        }
      />
    </Routes>
  );
};

export default Router;
