import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { BasicSelectWithoutForm } from "@datwyler/shared-components";

const Order = (props) => {
  const { order, setOrder } = props;
  const intl = useIntl();

  const options = [
    {
      priority: 0,
      label: intl.formatMessage({ id: "type" }),
      value: "type",
    },
  ];

  const handleChangeOrder = (e) => {
    setOrder(e.target.value);
  };

  return (
    <Box sx={{ marginLeft: "24px", width: "190px", height: "44px" }}>
      <BasicSelectWithoutForm
        menuItems={options}
        formItemLabel={"order"}
        label={intl.formatMessage({ id: "sort_by" })}
        value={order}
        onChange={handleChangeOrder}
        SelectDisplayProps={{
          paddingTop: "12px",
          paddingBottom: "12px",
          paddingLeft: "16px",
          maxWidth: "unset",
        }}
        inputLabelSx={{
          fontFamily: "NotoSans-Medium",
          fontSize: "14px",
          letterSpacing: "0px",
          fontWeight: "500",
          lineHeight: "20px",
          top: "2px",
          maxWidth: "unset",
        }}
        menuPropsSx={{
          marginLeft: "102px",
          maxHeight: "352px",
        }}
      />
    </Box>
  );
};

export default Order;
