import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import DeviceTopComponent from "../components/DeviceDetails/DeviceTopComponent";
import MenuTabs from "../components/DeviceDetails/MenuTabs";
import DeviceStatusCards from "../components/DeviceDetails/DeviceStatusCards";
import Temperature from "../components/DeviceDetails/Temperature";
import SignalAreaChart from "../components/DeviceDetails/SignalAreaChart";
import { ACTIVE } from "../utils/constants";
import DeviceDetailTable from "../components/DeviceDetails/DeviceDetailTable";
import DeviceDialog from "../components/common/DeviceDialog";
import DeleteDeviceDialog from "../components/common/DeleteDeviceDialog";
import RestartDialog from "../components/DeviceDetails/Dialogs/RestartDialog";
import UpdateLanSettingDialog from "../components/DeviceDetails/Dialogs/UpdateLanSettingDialog";
import UpdateFirmwareDialog from "../components/DeviceDetails/Dialogs/UpdateFirmwareDialog";
import AlarmRuleDialog from "../components/DeviceDetails/Dialogs/AlarmRuleDialog";
import DeleteAlarmRuleDialog from "../components/DeviceDetails/Dialogs/DeleteAlarmRuleDialog";
import SetParameter from "../components/DeviceDetails/SetParameter";
import SiteMenu from "../components/DeviceDetails/SiteMenu";
import AlarmRule from "../components/DeviceDetails/AlarmRule";
import { useIntl } from "react-intl";
import {
  useTelemetry,
  useLoadingGif,
  useDevice,
  useGatewayActions,
  useAlarmRules,
  useSnackbar,
  TenantIdContext,
} from "@datwyler/shared-components";
import { isShowGatewayScreen } from "../utils";

let fetchDataInterval;

const paddingTop = "76px";
const siteMenuWidth = "225px";

let currentDeviceId = "";

const getParameters = (device) => {
  return device?.telemetry?.filter(
    (data) => data.name.substring(0, 3) === "set"
  );
};

const DeviceDetails = () => {
  const { tenantId }: any = useContext(TenantIdContext);
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const { fetchTelemetryData, fetchTelemetry } = useTelemetry();
  const [dialogOpened, setDialogOpened] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDevice, setSelectedDevice] = useState(
    location.state.device || {}
  );
  const [selectedAlarmRules, setSelectedAlarmRules] = useState([]);

  const {
    fetchAlarmRules,
    isFetchAlarmRulesLoading,
    fetchAlarmRulesData,
    addAlarmRule,
    isAddAlarmRuleLoading,
    addAlarmRuleResponseData,
    resetAddAlarmRuleData,
    updateAlarmRule,
    isUpdateAlarmRuleLoading,
    updateAlarmRuleResponseData,
    resetUpdateAlarmRuleData,
  } = useAlarmRules();

  // Alarm Rules Table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  // devices is a list of devices in the same site
  const [devices, setDevices] = useState(location?.state?.devices || []);

  // Parameters for device actions (not including Gateway)
  const [parameters, setParameters] = useState([]);

  const {
    updateDevice,
    isUpdateDeviceLoading,
    updateDeviceResponseData,
    resetUpdateDeviceData,
    fetchDeviceById,
    fetchDeviceByIdData,
    batchUpdate,
    fetchDeviceData,
    fetchDevices,
  } = useDevice();

  const {
    restartDevice,
    isRestartDeviceLoading,
    restartDeviceResponseData,
    updateLanSetting,
    isUpdateLanSettingLoading,
    updateLanSettingResponseData,
  } = useGatewayActions();

  useEffect(() => {
    refetchDevices();
    return () => {
      clearInterval(fetchDataInterval);
      currentDeviceId = "";
    };
  }, []);

  useEffect(() => {
    refetchAlarmRules();
  }, [selectedDevice, page, rowsPerPage]);

  useEffect(() => {
    if (selectedDevice.id !== currentDeviceId) {
      // perform these only when device is changed
      const isScrollBarVisible =
        document.body.scrollHeight > document.body.clientHeight;
      if (isScrollBarVisible) {
        window.dispatchEvent(new Event("changeWidthWithScrollBar"));
      }

      intervalFetchData(selectedDevice, tenantId);
      setFetchDataInterval(selectedDevice);

      currentDeviceId = selectedDevice.id;
      setActiveTab(0);
    }
    setParameters(getParameters(selectedDevice));
  }, [selectedDevice, tenantId]);

  useEffect(() => {
    if (fetchAlarmRulesData?.alarmRules?.page) {
      setTotalRows(fetchAlarmRulesData.alarmRules.page.totalElements);
    }
  }, [fetchAlarmRulesData]);

  useEffect(() => {
    setIsLoading(
      isUpdateDeviceLoading ||
        isRestartDeviceLoading ||
        isUpdateLanSettingLoading ||
        isAddAlarmRuleLoading ||
        isUpdateAlarmRuleLoading ||
        false
    );
  }, [
    isUpdateDeviceLoading,
    isRestartDeviceLoading,
    isUpdateLanSettingLoading,
    isAddAlarmRuleLoading,
    isUpdateAlarmRuleLoading,
    isFetchAlarmRulesLoading,
  ]);

  useEffect(() => {
    if (fetchDeviceByIdData?.deviceById)
      setSelectedDevice(fetchDeviceByIdData.deviceById);
  }, [fetchDeviceByIdData]);

  useEffect(() => {
    if (updateDeviceResponseData?.updateDevice?.device) {
      resetUpdateDeviceData();
      if (!(dialogOpened === "DeleteDeviceDialog")) {
        setSelectedDevice(updateDeviceResponseData.updateDevice.device);
        enqueueSnackbar(intl.formatMessage({ id: "device_updated" }), {
          variant: "success",
        });
        setDialogOpened("");
      }

      refetchDevices();
    }
  }, [updateDeviceResponseData]);

  useEffect(() => {
    if (fetchDeviceData?.devices?.devices) {
      setDevices(fetchDeviceData?.devices?.devices);
    }
  }, [fetchDeviceData]);

  const refetchDevices = () => {
    fetchDevices({
      variables: {
        tenantId: tenantId,
        page: 0,
        size: 99999,
        filter: [`siteId:${selectedDevice?.site?.id},status:${ACTIVE}`],
      },
    });
  };

  const refetchAlarmRules = () => {
    fetchAlarmRules({
      variables: {
        tenantId: tenantId,
        page: page,
        size: rowsPerPage,
        filter: [`deviceId:${selectedDevice?.id},status:${ACTIVE}`],
        sort: ["updatedOn,desc"],
      },
    });
  };

  const setFetchDataInterval = (selectedDevice) => {
    // fetch data every 15sec
    clearInterval(fetchDataInterval);
    fetchDataInterval = setInterval(
      () => intervalFetchData(selectedDevice, tenantId),
      15000
    );
  };

  const intervalFetchData = (selectedDevice, tenantId) => {
    const now = new Date();
    const startTime = Math.floor(now.setHours(now.getHours() - 1) / 1000); // past 1 hour

    fetchTelemetry({
      variables: {
        tenantId: tenantId,
        deviceIds: [selectedDevice.id],
        startTime: startTime,
        page: 0,
        size: 99999, // set a huge page size
        sort: ["time,asc"],
        filter: ["name:modem_temperature|name:modem_signal"],
      },
    });

    refetchDeviceById();
  };

  const refetchDeviceById = () => {
    fetchDeviceById({
      variables: { id: selectedDevice.id },
    });
  };

  const getContent = () => {
    if (isShowGatewayScreen(selectedDevice?.model?.id)) {
      return (
        <>
          {activeTab === 0 && (
            <>
              <DeviceStatusCards device={selectedDevice} />
              <Temperature
                device={selectedDevice}
                data={fetchTelemetryData?.telemetries?.telemetries}
              />
              <SignalAreaChart
                device={selectedDevice}
                data={fetchTelemetryData?.telemetries?.telemetries}
              />
            </>
          )}
          {activeTab === 1 && (
            <AlarmRule
              alarmRules={fetchAlarmRulesData?.alarmRules?.alarmRules}
              openDialog={openDialog}
              selectedAlarmRules={selectedAlarmRules}
              setSelectedAlarmRules={setSelectedAlarmRules}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              totalRows={totalRows}
            />
          )}
        </>
      );
    } else {
      const hasSetParameter = parameters?.length > 0;
      return (
        <>
          {activeTab === 0 && (
            <>
              <DeviceStatusCards device={selectedDevice} />
              <DeviceDetailTable device={selectedDevice} />
            </>
          )}
          {activeTab === 1 && hasSetParameter && (
            <SetParameter
              device={selectedDevice}
              parameters={parameters}
              tenantId={tenantId}
            />
          )}
        </>
      );
    }
  };

  const handleAfterDelete = (allResponses) => {
    // find responses that has errors
    // todo: add find errors when BE added errors
    const hasErrors = allResponses.some(
      (response) => !response?.data?.updateDevice?.device
    );
    if (!hasErrors) navigate("/device-monitoring", { state: location.state });
  };

  const openDialog = (dialogName) => {
    setDialogOpened(dialogName);
  };

  const closeDialog = () => {
    setDialogOpened("");
  };

  return (
    <Box sx={{ minHeight: "100vh", display: "flex" }}>
      <LoadingGif />
      <Box sx={{ display: "inline-flex", verticalAlign: "top" }}>
        <SiteMenu
          selectedDevice={selectedDevice}
          devices={devices}
          paddingTop={paddingTop}
          siteMenuWidth={siteMenuWidth}
          setSelectedDevice={setSelectedDevice}
        />
      </Box>
      <Box
        sx={{
          width: `calc(100% - ${siteMenuWidth} - 24px - 48px)`,
          maxWidth: `calc(100% - ${siteMenuWidth} - 24px - 48px)`,
          display: "inline-block",
          paddingTop: "70px",
          paddingLeft: "24px",
          paddingRight: "24px",
          minHeight: `calc(100% - ${paddingTop})`,
        }}
      >
        <DeviceTopComponent
          device={selectedDevice}
          openDialog={openDialog}
          tenantId={tenantId}
        />
        <MenuTabs
          device={selectedDevice}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          parameters={parameters}
        />
        {getContent()}
        <DeviceDialog
          tenantId={tenantId}
          isOpen={dialogOpened === "DeviceDialog"}
          updateDevice={updateDevice}
          device={selectedDevice}
          closeDialog={closeDialog}
        />
        <DeleteDeviceDialog
          isOpen={dialogOpened === "DeleteDeviceDialog"}
          devices={[selectedDevice]}
          batchUpdate={batchUpdate}
          handleAfterDelete={handleAfterDelete}
          closeDialog={closeDialog}
        />
        <RestartDialog
          isOpen={dialogOpened === "RestartDialog"}
          device={selectedDevice}
          closeDialog={closeDialog}
          restartDevice={restartDevice}
          restartDeviceResponseData={restartDeviceResponseData}
          tenantId={tenantId}
        />
        <UpdateFirmwareDialog
          isOpen={dialogOpened === "UpdateFirmwareDialog"}
          device={selectedDevice}
          closeDialog={closeDialog}
          tenantId={tenantId}
        />
        <AlarmRuleDialog
          isOpen={dialogOpened === "AlarmRuleDialog"}
          device={selectedDevice}
          closeDialog={closeDialog}
          tenantId={tenantId}
          addAlarmRule={addAlarmRule}
          addAlarmRuleResponseData={addAlarmRuleResponseData}
          resetAddAlarmRuleData={resetAddAlarmRuleData}
          updateAlarmRule={updateAlarmRule}
          updateAlarmRuleResponseData={updateAlarmRuleResponseData}
          resetUpdateAlarmRuleData={resetUpdateAlarmRuleData}
          setSelectedAlarmRules={setSelectedAlarmRules}
          selectedAlarmRules={selectedAlarmRules}
          refetchAlarmRules={refetchAlarmRules}
        />
        <UpdateLanSettingDialog
          isOpen={dialogOpened === "UpdateLanSettingDialog"}
          device={selectedDevice}
          closeDialog={closeDialog}
          updateLanSetting={updateLanSetting}
          updateLanSettingResponseData={updateLanSettingResponseData}
          tenantId={tenantId}
        />
        <DeleteAlarmRuleDialog
          isOpen={dialogOpened === "DeleteAlarmRuleDialog"}
          setSelectedAlarmRules={setSelectedAlarmRules}
          selectedAlarmRules={selectedAlarmRules}
          updateAlarmRule={updateAlarmRule}
          resetUpdateAlarmRuleData={resetUpdateAlarmRuleData}
          closeDialog={closeDialog}
          refetchAlarmRules={refetchAlarmRules}
        />
      </Box>
    </Box>
  );
};

export default DeviceDetails;
