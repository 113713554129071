export const getTelemetryData = (telemetry, key) => {
  const data = telemetry?.find((data) => data.name === key);
  return data?.value;
};

// Example of dataFromDb:
// [
//     {
//       attribute_name: string
//       queryTime: unix timstamp
//       value: int
//     }
// ],...

export const getTemperatureData = (dataFromDb, device) => {
  const returnData = [
    {
      id: device.name,
      color: "#009fb4",
      data: [],
    },
  ];

  if (dataFromDb) {
    dataFromDb.forEach((row) => {
      if (row.name === "modem_temperature") {
        returnData[0].data.push({
          x: new Date(row.time * 1000),
          y: parseFloat(row.value),
        });
      }
    });
  }

  return returnData;
};

export const getSignalData = (dataFromDb, device) => {
  const returnData = [
    {
      id: device.name,
      color: "hsl(216, 70%, 50%)",
      data: [],
    },
  ];

  if (dataFromDb) {
    dataFromDb.forEach((row) => {
      if (row.name === "modem_signal") {
        returnData[0].data.push({
          x: new Date(row.time * 1000),
          y: row.value,
        });
      }
    });
  }
  return returnData;
  // EXAMPLE:
  // let count = 0;

  // return [
  //   {
  //     id: "Device Signal",
  //     color: "hsl(216, 70%, 50%)",
  //     data: [
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -82,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -81,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -83,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -85,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -78,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -70,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -100,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -111,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -81,
  //       },
  //       {
  //         x: new Date(today.getTime() + ++count * 60000),
  //         y: -86,
  //       },
  //     ],
  //   },
  // ];
};

export const copyToClipboard = (device, enqueueSnackbar, intl) => {
  try {
    if (navigator?.clipboard) {
      // this uses https but Currently SIT is not secured
      navigator.clipboard.writeText(device.id);
    } else {
      // Unsecured, document.execCommand is deprecated
      unsecuredCopy(device.id);
    }
  } catch (exception) {
    enqueueSnackbar(
      intl.formatMessage({ id: "error" }) + ": " + exception.message,
      {
        variant: "error",
      }
    );
    return;
  }
  enqueueSnackbar(intl.formatMessage({ id: "device_id_copy_success" }), {
    variant: "success",
  });
};

const unsecuredCopy = (text) => {
  var textarea = document.createElement("textarea");
  textarea.textContent = text;
  document.body.appendChild(textarea);

  var selection = document.getSelection();
  var range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand("copy");
  selection.removeAllRanges();

  document.body.removeChild(textarea);
};

export const isShowGatewayScreen = (modelId) => {
  const allowedModelIds = [
    "46ec17f0-38a0-4fee-93cb-733b5c7cf917", // RUTX11
    "aa9059af-cf2e-465d-b0f2-91616a817cf9", // TRB140
  ];

  return allowedModelIds.includes(modelId);
};
