import { useState, useRef, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  useSnackbar,
  ApiContext,
  DragAndDropFileUpload,
} from "@datwyler/shared-components";

const UpdateFirmwareDialog = (props) => {
  const { isOpen = false, device, closeDialog, tenantId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [firmwareFile, setFirmwareFile] = useState(null);
  const inputRef = useRef(null);
  const { uri, apiInstance }: any = useContext(ApiContext);
  const { colors }: any = useContext(DarkModeContext);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", firmwareFile);

    // call api to upload firmware file
    const url =
      uri +
      "/tenants/" +
      tenantId +
      "/devices/" +
      device.id +
      "/firmware-update";
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    apiInstance
      .post(url, formData, config)
      .then((response) => {
        if (response?.status === 200) {
          enqueueSnackbar(
            intl.formatMessage({ id: "firmware_upload_success" }),
            {
              variant: "success",
            }
          );
          closeDialog();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const handleFileUpload = (file) => {
    // Check the file extension, currently only accept .bin firmware files
    const fileExtension = file?.name?.split(".").pop();
    if (fileExtension) {
      if (fileExtension === "bin") {
        // if correct, set the firmware file and return
        setFirmwareFile(file);
        return;
      } else {
        enqueueSnackbar(
          intl.formatMessage({ id: "err_file_upload_ext" }) + " .bin",
          {
            variant: "error",
          }
        );
        return;
      }
    }
    enqueueSnackbar(intl.formatMessage({ id: "err_file_upload" }), {
      variant: "error",
    });
  };

  const dragAndDropFont = {
    fontFamily: "NotoSans-Regular",
    fontSize: 14,
    color: colors.updateFirmwareBgDragFont,
    letterSpacing: "0.01px",
    lineHeight: "16px",
    display: "inline-flex",
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "update_firmware" })}
        </Typography>
        <IconButton
          onClick={closeDialog}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent>
        <DragAndDropFileUpload
          inputRef={inputRef}
          isMultiple={false}
          fileTypes={".bin"}
          onFileUpload={handleFileUpload}
          file={firmwareFile}
          setFile={setFirmwareFile}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", paddingBottom: "24px" }}>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={closeDialog} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={handleUpload}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
          >
            {intl.formatMessage({ id: "upload" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateFirmwareDialog;
