import { useContext, useEffect } from "react";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LocationContext from "../../../../context/LocationContext";
import {
  DarkModeContext,
  getDeviceImage,
  getLocationFromSiteId,
  BasicCard,
} from "@datwyler/shared-components";

const DeviceCard = (props) => {
  const { device, handleOnClick } = props;
  const { locations }: any = useContext(LocationContext);
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    const isScrollBarVisible =
      document.body.scrollHeight > document.body.clientHeight;
    if (isScrollBarVisible) {
      window.dispatchEvent(new Event("changeWidthWithScrollBar"));
    }
  }, []);

  return (
    <BasicCard
      sx={{
        borderRadius: "8px",
        boxShadow: "none",
        minWidth: "262px",
        padding: 0,
      }}
      onClick={handleOnClick}
    >
      <CardActionArea>
        <CardHeader
          title={device?.name}
          titleTypographyProps={{
            color: colors.deviceCardHeaderFont,
            fontFamily: "NotoSans-Medium",
            fontSize: "16px",
            fontWeight: "500",
            letterSpacing: "0",
            lineHeight: "20px",
          }}
        />
        <CardMedia
          component="img"
          height="193"
          alt="device image"
          image={getDeviceImage(device?.model?.id, device?.model?.deviceType)}
          title={device.model.name}
          sx={{
            padding: "16px 45px 28px 45px",
            objectFit: "contain",
            width: "calc(100% - 90px)",
            height: "149px",
            backgroundColor: colors.deviceCardBg,
          }}
        />
        <CardContent>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "0.01px",
              lineHeight: "24px",
            }}
          >
            {getLocationFromSiteId(locations, device.site.id)?.name}
          </Typography>

          <Typography
            sx={{
              color: colors.deviceCardFont,
              fontFamily: "NotoSans-Regular",
              fontSize: "12px",
              letterSpacing: "0.01px",
              lineHeight: "16px",
            }}
          >
            {device.site.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </BasicCard>
  );
};

export default DeviceCard;
