import { SyntheticEvent, useContext } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useIntl } from "react-intl";
import { DarkModeContext } from "@datwyler/shared-components";
import { isShowGatewayScreen } from "../../utils";

const MenuTabs = (props) => {
  const { device, activeTab, setActiveTab, parameters = [] } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const tabStyle = {
    backgroundColor: "unset",
    color: colors.blueGray400,
    fontFamily: "NotoSans-SemiBold",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0.02px",
    lineHeight: "20px",
    textTransform: "unset",
    padding: "12px 8px",
    minWidth: "76px",
    minHeight: 0,
    marginRight: "24px",
    "&.Mui-selected": {
      color: colors.datwylerTeal,
      borderBottom: "1px solid " + colors.datwylerTeal,
    },
  };

  const getTabs = () => {
    if (isShowGatewayScreen(device?.model?.id)) {
      return ["information", "alarm_rule"];
    } else {
      const tabs = ["device_detail"];
      if (parameters?.length > 0) tabs.push("set_parameter");
      return tabs;
    }
  };

  return (
    <Box sx={{ marginTop: "24px" }}>
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        TabIndicatorProps={{
          style: {
            backgroundColor: "unset",
          },
        }}
        sx={{
          minHeight: 0,
        }}
      >
        {getTabs().map((tabName) => (
          <Tab
            key={tabName}
            label={intl.formatMessage({ id: tabName })}
            disableRipple
            sx={tabStyle}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default MenuTabs;
