import { useState, useEffect, useContext } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { getTelemetryData } from "../../../../utils";
import { ENUM_ON } from "../../../../utils/constants";
import {
  DarkModeContext,
  BasicRadialBarChart,
  getSignalLabel,
  BasicCard,
} from "@datwyler/shared-components";

const SignalGauge = (props: any) => {
  const { cardHeight, device } = props;
  const [data, setData] = useState([]);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const [signalStrength, setSignalStrength] = useState(null);
  const [signalLabel, setSignalLabel] = useState(
    intl.formatMessage({ id: "no_signal" })
  );

  useEffect(() => {
    const tempSignalStrength = getSignalStrength();
    setSignalStrength(tempSignalStrength);
    setSignalLabel(getSignalLabel(tempSignalStrength));
    setData(getData(tempSignalStrength));
  }, [device]);

  const getData = (tempSignalStrength) => {
    if (!tempSignalStrength) {
      return [
        {
          id: "Signal",
          data: [
            {
              x: "signal",
              y: -110,
            },
            {
              x: "",
              y: 0,
            },
          ],
        },
      ];
    }

    return [
      {
        id: "Signal",
        data: [
          {
            x: "signal",
            y: tempSignalStrength,
          },
          {
            x: "",
            y: -(110 + tempSignalStrength),
          },
        ],
      },
    ];
  };

  const getSignalStrength = () => {
    const status = getTelemetryData(device.telemetry, "status");
    if (status !== ENUM_ON) {
      return null;
    }
    const signal = getTelemetryData(device.telemetry, "modem_signal");
    if (signal) {
      return parseInt(signal);
    } else return null;
  };

  const getConnectionType = () => {
    if (!signalStrength) return intl.formatMessage({ id: "not_available" });
    else {
      return getTelemetryData(device.telemetry, "modem_connection_type");
    }
  };

  const getConnectionUptime = () => {
    if (!signalStrength) return intl.formatMessage({ id: "not_available" });
    const uptime = getTelemetryData(device.telemetry, "uptime");
    if (uptime) {
      const uptimeArr = uptime.split(":");
      const days = uptimeArr[0];
      const hours = uptimeArr[1];
      const min = uptimeArr[2];
      const sec = uptimeArr[3].substring(0, uptimeArr[3].indexOf("."));

      let uptimeString = "";
      if (uptimeArr[0] != "0")
        uptimeString += ` ${days} ${intl.formatMessage({ id: "days" })}`;
      if (uptimeArr[1] != "0")
        uptimeString += ` ${hours} ${intl.formatMessage({ id: "hours" })}`;
      if (uptimeArr[2] != "0")
        uptimeString += ` ${min} ${intl.formatMessage({ id: "minutes" })}`;
      if (uptimeArr[3] != "0")
        uptimeString += ` ${sec} ${intl.formatMessage({ id: "seconds" })}`;

      return uptimeString;
    }

    return intl.formatMessage({ id: "not_available" });
  };

  const keyBoxStyle = {
    display: "inline-flex",
    width: "128px",
  };

  const valueBoxStyle = {
    marginLeft: "16px",
    display: "inline-flex",
  };

  const keyStyle = {
    color: colors.deviceInformationFontKey,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0px",
    lineHeight: "20px",
    wordBreak: "break-all",
  };

  const valueStyle = {
    color: colors.deviceInformationFontValue,
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0px",
    lineHeight: "20px",
  };

  const getGaugeLabel = () => {
    if (signalStrength) {
      return (
        intl.formatMessage({ id: "signal_strength" }) +
        ": " +
        signalStrength +
        " dBm (" +
        intl.formatMessage({ id: signalLabel }) +
        ")"
      );
    }

    return intl.formatMessage({ id: signalLabel });
  };

  return (
    <BasicCard sx={{ height: cardHeight, boxShadow: "none", padding: 0 }}>
      <CardContent style={{ padding: "16px 24px, 16px 16px" }}>
        <Box>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "0px",
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: "connection" })}
          </Typography>
        </Box>
        <Box
          style={{ justifyContent: "center", width: "100%", height: "208px" }}
        >
          <BasicRadialBarChart
            data={data}
            colors={[colors.white, colors.datwylerTeal]}
            maxValue={-110}
          />
        </Box>
        <Box style={{ textAlign: "center", marginTop: "-70px" }}>
          <Typography
            sx={{
              color: colors.deviceInformationFontKey,
              fontFamily: "NotoSans-Regular",
              fontSize: "12px",
              letterSpacing: 0,
              lineHeight: "13px",
            }}
          >
            {getGaugeLabel()}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "13px" }}>
          <Box>
            <Box sx={keyBoxStyle}>
              <Typography sx={keyStyle}>
                {intl.formatMessage({ id: "conn_type" })}
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography sx={valueStyle}>{getConnectionType()}</Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: "8px" }}>
            <Box sx={keyBoxStyle}>
              <Typography sx={keyStyle}>
                {intl.formatMessage({ id: "conn_state" })}
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                sx={{
                  ...valueStyle,
                  color: signalStrength ? colors.datwylerTeal : colors.red500,
                }}
              >
                {intl.formatMessage({
                  id: signalStrength ? "connected" : "disconnected",
                })}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: "8px" }}>
            <Box sx={keyBoxStyle}>
              <Typography sx={keyStyle}>
                {intl.formatMessage({ id: "conn_uptime" })}
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography sx={valueStyle}>{getConnectionUptime()}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </BasicCard>
  );
};

export default SignalGauge;
