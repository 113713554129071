import { useState, MouseEvent, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import {
  BasicSelectWithCheckBox,
  BasicButton,
  FilterIcon,
  DarkModeContext,
} from "@datwyler/shared-components";
import LayoutSelect from "../LayoutSelect";

const filterContainerStyle = {
  display: "inline-flex",
  height: "44px",
  marginLeft: "24px",
};

const Filters = (props) => {
  const {
    sites = [],
    models = [],
    defaultFilters,
    setFilters,
    layout,
    handleChangeLayout,
  } = props;
  const [interimFilters, setInterimFilters] = useState(defaultFilters);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [siteOptions, setSiteOptions] = useState([]);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (sites.length > 0) {
      const siteOptions = [];
      sites.forEach((site, index) => {
        siteOptions.push({
          priority: index,
          label: site?.name,
          value: site?.id,
        });
      });
      setSiteOptions(siteOptions);
    }
  }, [sites]);

  useEffect(() => {
    if (models.length > 0) {
      const deviceTypeOptions = [];
      const checkAddedDeviceTypes = [];

      models.forEach((model, index) => {
        if (!checkAddedDeviceTypes.includes(model.deviceType)) {
          deviceTypeOptions.push({
            priority: index,
            label: intl.formatMessage({ id: model.deviceType }),
            value: model.deviceType,
          });
          checkAddedDeviceTypes.push(model.deviceType);
        }
      });
      setDeviceTypeOptions(deviceTypeOptions);
    }
  }, [models]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSite = (sites: Array<string>) => {
    setInterimFilters({ ...interimFilters, sites: sites });
  };

  const handleChangeDeviceType = (deviceTypes: Array<string>) => {
    setInterimFilters({ ...interimFilters, deviceTypes: deviceTypes });
  };

  const applyFilters = () => {
    setFilters(interimFilters);
    handleClose();
  };

  const resetFilters = () => {
    setInterimFilters(defaultFilters);
  };

  return (
    <Box>
      <Box sx={{ paddingTop: "16px", display: "flex", alignItems: "center" }}>
        <Box sx={filterContainerStyle}>
          <BasicButton
            variant="outlined"
            onClick={handleClick}
            sx={{ border: "1px solid " + colors.blueGray300 }}
          >
            <FilterIcon fill={colors.iconColor} />
            <Typography
              sx={{
                fontFamily: "NotoSans-Medium",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: "20px",
                marginLeft: "10px",
              }}
            >
              {intl.formatMessage({ id: "filters" })}
            </Typography>
          </BasicButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
                borderRadius: "8px",
                backgroundColor: colors.selectDropDownBg,
              },
            }}
          >
            <Box sx={{ padding: "16px" }}>
              <Box sx={{ width: "230px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeSite}
                  values={interimFilters.sites}
                  label={intl.formatMessage({ id: "sites" })}
                  menuItems={siteOptions}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeDeviceType}
                  values={interimFilters.deviceTypes}
                  label={intl.formatMessage({ id: "device_type" })}
                  menuItems={deviceTypeOptions}
                />
              </Box>

              <Box
                sx={{ width: "230px", marginTop: "16px", textAlign: "right" }}
              >
                <BasicButton
                  variant={"outlined"}
                  onClick={resetFilters}
                  sx={{
                    padding: "12px 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "NotoSans-Regular",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    }}
                  >
                    {intl.formatMessage({ id: "reset" })}
                  </Typography>
                </BasicButton>
                <BasicButton
                  variant={"contained"}
                  onClick={applyFilters}
                  sx={{ marginLeft: "20px", padding: "12px 16px" }}
                >
                  {intl.formatMessage({ id: "apply" })}
                </BasicButton>
              </Box>
            </Box>
          </Popover>
        </Box>
        <LayoutSelect layout={layout} handleChangeLayout={handleChangeLayout} />
      </Box>
    </Box>
  );
};

export default Filters;
