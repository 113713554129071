import { useState, useEffect, useContext } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { getTelemetryData } from "../../../../utils";
import LocationContext from "../../../../context/LocationContext";
import {
  DarkModeContext,
  getDeviceImage,
  getLocationFromSiteId,
  BasicCard,
} from "@datwyler/shared-components";

const DeviceInformation = (props: any) => {
  const { cardHeight, device } = props;
  const { locations }: any = useContext(LocationContext);
  const [deviceLocation, setDeviceLocation] = useState("");
  const [deviceSerial, setDeviceSerial] = useState("");
  const [modemImei, setModemImei] = useState("");
  const [modemOperator, setModemOperator] = useState("");
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (!!device?.site?.id) {
      const tempLocation = getLocationFromSiteId(locations, device?.site?.id);
      if (tempLocation) setDeviceLocation(tempLocation.name);
    }

    setDeviceSerial(getTelemetryData(device?.telemetry, "device_serial"));
    setModemImei(getTelemetryData(device?.telemetry, "modem_imei"));
    setModemOperator(getTelemetryData(device?.telemetry, "modem_operator"));
  }, [device, locations]);

  const rowStyle = {
    marginTop: "8px",
  };
  const keyBoxStyle = {
    display: "inline-flex",
    width: "42%",
  };

  const valueBoxStyle = {
    marginLeft: "16px",
    display: "inline-flex",
  };

  const keyStyle = {
    color: colors.deviceInformationFontKey,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0px",
    lineHeight: "20px",
  };

  const valueStyle = {
    color: colors.deviceInformationFontValue,
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0px",
    lineHeight: "20px",
  };

  const modelNameStyle = {
    color: colors.datwylerTeal,
    fontFamily: "NotoSans-SemiBold",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "0px",
    lineHeight: "20px",
  };

  return (
    <BasicCard sx={{ height: cardHeight, boxShadow: "none", padding: 0 }}>
      <CardContent style={{ padding: "16px 24px, 16px 16px" }}>
        <Box>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "0px",
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: "device_information" })}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", marginTop: "24px" }}>
          <Box
            sx={{
              display: "inline-flex",
              width: "40%",
              height: "168px",
              backgroundColor: colors.deviceInformationImgBg1,
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                backgroundColor: colors.deviceInformationImgBg2,
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                alt={device?.model?.id}
                src={getDeviceImage(device?.model?.id)}
                style={{ height: "110px" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "inline-block",
              height: "200px",
              marginLeft: "16px",
              width: "60%",
            }}
          >
            <Box>
              <Box sx={keyBoxStyle}>
                <Typography sx={keyStyle}>
                  {intl.formatMessage({ id: "model" })}
                </Typography>
              </Box>
              <Box sx={valueBoxStyle}>
                <Typography sx={modelNameStyle}>
                  {device?.model?.name}
                </Typography>
              </Box>
            </Box>
            <Box sx={rowStyle}>
              <Box sx={keyBoxStyle}>
                <Typography sx={keyStyle}>
                  {intl.formatMessage({ id: "location" })}
                </Typography>
              </Box>
              <Box sx={valueBoxStyle}>
                <Typography sx={valueStyle}> {deviceLocation} </Typography>
              </Box>
            </Box>
            <Box sx={rowStyle}>
              <Box sx={keyBoxStyle}>
                <Typography sx={keyStyle}>
                  {intl.formatMessage({ id: "site" })}
                </Typography>
              </Box>
              <Box sx={valueBoxStyle}>
                <Typography sx={valueStyle}> {device?.site?.name} </Typography>
              </Box>
            </Box>
            <Box sx={rowStyle}>
              <Box sx={keyBoxStyle}>
                <Typography sx={keyStyle}>
                  {intl.formatMessage({ id: "device_type" })}
                </Typography>
              </Box>
              <Box sx={valueBoxStyle}>
                <Typography sx={valueStyle}>
                  {" "}
                  {intl.formatMessage({ id: device?.model?.deviceType })}{" "}
                </Typography>
              </Box>
            </Box>
            <Box sx={rowStyle}>
              <Box sx={keyBoxStyle}>
                <Typography sx={keyStyle}>
                  {intl.formatMessage({ id: "serial_number" })}
                </Typography>
              </Box>
              <Box sx={valueBoxStyle}>
                <Typography sx={valueStyle}> {deviceSerial} </Typography>
              </Box>
            </Box>
            <Box sx={rowStyle}>
              <Box sx={keyBoxStyle}>
                <Typography sx={keyStyle}>
                  {intl.formatMessage({ id: "modem_imei" })}
                </Typography>
              </Box>
              <Box sx={valueBoxStyle}>
                <Typography sx={valueStyle}> {modemImei} </Typography>
              </Box>
            </Box>
            <Box sx={rowStyle}>
              <Box sx={keyBoxStyle}>
                <Typography sx={keyStyle}>
                  {intl.formatMessage({ id: "modem_operator" })}
                </Typography>
              </Box>
              <Box sx={valueBoxStyle}>
                <Typography sx={valueStyle}> {modemOperator} </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </BasicCard>
  );
};

export default DeviceInformation;
